import { useEffect } from 'react';

import ViewSDKClient from '../ViewSDKClient';

const SizedContainer = ({ attributes, clientId, pebAPIKey }) => {
	useEffect(() => {
		const viewSDKClient = new ViewSDKClient();

		viewSDKClient.ready().then(() => {
			viewSDKClient.previewFile(`pebPDFArea-${clientId}`, { embedMode: 'SIZED_CONTAINER' }, attributes, pebAPIKey);
		});
	}, []);

	return <div className='pebSizedContainer' id={`pebPDFArea-${clientId}`}></div>
}
export default SizedContainer;