class ViewSDKClient {
	constructor() {
		this.readyPromise = new Promise(resolve => {
			if (window.AdobeDC) {
				resolve();
			} else {
				// Wait for Adobe Document Services PDF Embed API to be ready
				document.addEventListener('adobe_dc_view_sdk.ready', () => resolve());
			}
		});

		this.adobeDCView = undefined;
	}

	ready() {
		return this.readyPromise;
	}

	previewFile(divId, viewerConfig, attributes, pebAPIKey) {
		const { file } = attributes;

		const defaultConfig = { clientId: pebAPIKey };
		if (divId) { // Optional only for Light Box embed mode
			defaultConfig.divId = divId;
		}

		// Initialize the AdobeDC View object
		this.adobeDCView = new window.AdobeDC.View(defaultConfig);

		// Invoke the file preview API on Adobe DC View object
		const previewFilePromise = this.adobeDCView.previewFile({
			content: {
				// Location of file where it is hosted
				location: {
					url: file.url,
					// If the file URL requires some additional headers, then it can be passed as follows:-
					// headers: [ { key: '<HEADER_KEY>', value: '<HEADER_VALUE>' } ]
				}
			},

			metaData: {
				fileName: file.title || ' ',
				id: file.id?.toString()
			}
		}, {
			showDownloadPDF: false, // If true, a download button appears in the overflow menu on the top bar
			showPrintPDF: false, // If true, then a print PDF option appears in the overflow menu on the top bar
			showFullScreen: true, // If true, a full screen button appears. (Only for `SIZED_CONTAINER` embedMode)
			// https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/howtos/#embed-modes

			...viewerConfig
		});

		return previewFilePromise;
	}
}
export default ViewSDKClient;