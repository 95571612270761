const Style = ({ attributes, clientId }) => {
	const { alignment, width, height } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#pebPDFEmbed-${clientId} .pebPDFEmbed{
			text-align: ${alignment};
		}
		#pebPDFEmbed-${clientId} .pebPDFEmbed .pebSizedContainer{
			width: ${'0px' === width || '0%' === width || '0em' === width ? 'auto' : width};
			height: ${'0px' === height || '0vh' === height || '0em' === height ? 'auto' : height};
		}
		#pebPDFEmbed-${clientId} .pebPDFEmbed .pebInLine{
			width: ${'0px' === width || '0%' === width || '0em' === width ? 'auto' : width};
		}
	`}} />;
}
export default Style;