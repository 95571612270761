import { useEffect } from 'react';

import ViewSDKClient from '../ViewSDKClient';

const InLine = ({ attributes, clientId, pebAPIKey }) => {
	useEffect(() => {
		const viewSDKClient = new ViewSDKClient();

		viewSDKClient.ready().then(() => {
			viewSDKClient.previewFile(`pebPDFArea-${clientId}`, { embedMode: 'IN_LINE' }, attributes, pebAPIKey);
		});
	}, []);

	return <div className='pebInLine' id={`pebPDFArea-${clientId}`}></div>
}
export default InLine;