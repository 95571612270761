import SizedContainer from './Modes/SizedContainer';
import InLine from './Modes/InLine';

const PDFEmbed = ({ attributes, clientId, pebAPIKey }) => {
	const { config } = attributes;

	return <div className='pebPDFEmbed'>
		{'SIZED_CONTAINER' === config?.embedMode && <SizedContainer attributes={attributes} clientId={clientId} pebAPIKey={pebAPIKey} />}

		{'IN_LINE' === config?.embedMode && <InLine attributes={attributes} clientId={clientId} pebAPIKey={pebAPIKey} />}
	</div>;
}
export default PDFEmbed;