import { render } from 'react-dom';

import './style.scss';
import Style from './Style';
import PDFEmbed from './PDFEmbed';

// PDF Embed
document.addEventListener('DOMContentLoaded', () => {
	const allPDFEmbed = document.querySelectorAll('.wp-block-peb-pdf-embed');
	allPDFEmbed.forEach(pdfEmbed => {
		const { attributes, pebAPIKey } = JSON.parse(pdfEmbed.dataset.props);
		const { cId, file } = attributes;

		pebAPIKey && file?.url && render(<>
			<Style attributes={attributes} clientId={cId} />

			<PDFEmbed attributes={attributes} clientId={cId} pebAPIKey={pebAPIKey} />
		</>, pdfEmbed);

		pdfEmbed?.removeAttribute('data-props');
	});
});